import {
  Account,
  Client,
  Databases,
  Functions,
  Teams,
  Storage,
} from "appwrite";

const appwrite = new Client();

appwrite
  .setEndpoint("https://api.baitkw.com/v1") // Your API Endpoint
  .setProject("65016353dca1f3dcb21f"); // Your project ID

export const account = new Account(appwrite);

export const databases = new Databases(appwrite);

export const functions = new Functions(appwrite);

export const teams = new Teams(appwrite);

export const storage = new Storage(appwrite);

export const client = appwrite;
