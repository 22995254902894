import React from "react";
import "./css/Hero.css";
import Button from "./Button";
import { TypeAnimation } from "react-type-animation";
const Hero = () => (
	<section className="Hero" id="home">
		<div className="hero__container">
			<div className="hero__container__bg"></div>
			<div>
				<h1 className="hero__container__title">منصة بيت العقارية</h1>

				<TypeAnimation
					sequence={["بيت وجهتك العقارية الفريدة", 2000]}
					wrapper="h3"
					speed={50}
					style={{ fontSize: "2em", textAlign: "center", color: "#F8545F" }}
					repeat={Infinity}
				/>

				<p className="hero__container__desc" style={{ color: "#000" }}>
					منصة بيت العقارية هي وجهتك العقارية الفريدة في عالم العقارات بجميع
					أنواعها، نسعى لتقديم تجربة استثنائية من خلال عرض متنوع بالعقارات التي
					تلبي احتياجاتك{" "}
				</p>
				<div className="hero__container_stores">
					<Button
						className="hero_button_stores"
						link="./#RequestInvite"
						cont="App store"
					/>
					<Button
						className="hero_button_stores"
						link="./#RequestInvite"
						cont="Google play"
					/>
				</div>
			</div>
		</div>
	</section>
);

export default Hero;
