import React, { useEffect, useState } from "react";
import "./css/Articles.css";
import Article from "./Article";
import { databases } from "../appwrite/services";

const Articles = () => {
	const [data, setData] = useState([]);
	const [search, setSearch] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [visibleCount, setVisibleCount] = useState(12);

	useEffect(() => {
		const fetchProperties = async () => {
			try {
				const [rentProperties, saleProperties] = await Promise.all([
					databases.listDocuments("Properties", "Rent"),
					databases.listDocuments("Properties", "Buy"),
				]);
				const allProperties = [
					...rentProperties.documents,
					...saleProperties.documents,
				];
				setData(allProperties);
				setFilteredData(allProperties);
			} catch (error) {
				console.error("Error fetching properties:", error);
			}
		};

		fetchProperties();
	}, []);

	const handleSearch = (event) => {
		const searchQuery = event.target.value.toLowerCase();
		setSearch(searchQuery);

		const filtered = data.filter(
			(property) =>
				property.proprtyName.toLowerCase().includes(searchQuery) ||
				`${property.code}`.toLowerCase().includes(searchQuery),
		);
		setFilteredData(filtered);
	};

	const loadMore = () => {
		setVisibleCount((prev) => prev + 12);
	};

	return (
		<section className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8" id="properties">
			<div className="max-w-7xl mx-auto">
				<div className="text-center mb-12">
					<h2 className="text-4xl font-bold text-gray-900 mb-4">
						Our Properties
					</h2>
					<p className="text-lg text-gray-600">
						Find your perfect home from our carefully curated selection
					</p>
				</div>

				<div className="mb-8">
					<div className="max-w-md mx-auto">
						<div className="relative">
							<input
								type="text"
								value={search}
								onChange={handleSearch}
								placeholder="Search by name or code..."
								className="w-full px-6 py-4 bg-white border border-gray-200 rounded-full shadow-sm focus:ring-2 focus:ring-rose-500 focus:border-transparent outline-none transition duration-200 text-gray-900 placeholder-gray-500"
							/>
							<div className="absolute right-4 top-1/2 transform -translate-y-1/2">
								<svg
									className="w-5 h-5 text-gray-400"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
					{filteredData.slice(0, visibleCount).map((property) => (
						<Article
							key={property.$id}
							coll={property.$collectionId}
							cover={property.proprtyImg}
							id={property.$id}
							title={property.proprtyName}
							excerpt={property.description}
							price={
								property.$collectionId === "Rent"
									? `${property.Price} KWD/Month`
									: `${property.Price} KWD`
							}
						/>
					))}
				</div>

				{visibleCount < filteredData.length && (
					<div className="mt-12 text-center">
						<button
							type="button"
							onClick={loadMore}
							className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 transition duration-200"
						>
							Load More Properties
						</button>
					</div>
				)}
			</div>
		</section>
	);
};

export default Articles;
