import React from "react";
import "./css/Footer.css";
import { TwitterIcon, InstagramIcon, WhatsappIcon } from "./Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
	return (
		<footer className="bg-[#F8545F] text-white">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					{/* Logo & Social Media Column */}
					<div className="flex flex-col items-center md:items-start space-y-6">
						<div className="footer__container__logo" />

						<div className="flex space-x-4">
							<a
								href="https://twitter.com/find_bait"
								className="bg-white hover:bg-gray-100 p-2.5 rounded-md transition-colors duration-200 flex items-center justify-center shadow-sm"
							>
								<TwitterIcon />
							</a>
							<a
								href="https://www.instagram.com/findbait/"
								className="bg-white hover:bg-gray-100 p-2.5 rounded-md transition-colors duration-200 flex items-center justify-center shadow-sm"
							>
								<InstagramIcon />
							</a>
							<a
								href="https://api.whatsapp.com/send?phone=96599225309"
								className="bg-white hover:bg-gray-100 p-2.5 rounded-md transition-colors duration-200 flex items-center justify-center shadow-sm"
							>
								<WhatsappIcon />
							</a>
						</div>
					</div>

					{/* Links Column */}
					<div className="text-center md:text-right">
						<nav className="flex flex-col space-y-3">
							<a
								href="/about"
								className="text-white hover:text-gray-200 transition-colors duration-200"
							>
								About Us
							</a>
							<a
								href="/contactus"
								className="text-white hover:text-gray-200 transition-colors duration-200"
							>
								Contact
							</a>
							<a
								href="/terms"
								className="text-white hover:text-gray-200 transition-colors duration-200"
							>
								Terms and Conditions
							</a>
							<a
								href="/privacy"
								className="text-white hover:text-gray-200 transition-colors duration-200"
							>
								Privacy Policy
							</a>
						</nav>
					</div>
				</div>

				{/* Bottom Bar */}
				<div className="mt-12 pt-8 border-t border-white/20">
					<div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
						<p className="text-sm text-white/80">
							© {new Date().getFullYear()} Bait. All Rights Reserved
						</p>
						<p className="text-sm text-white/80 flex items-center">
							Made with{" "}
							<FontAwesomeIcon
								icon="fa-solid fa-heart"
								className="text-white mx-1"
							/>{" "}
							by{" "}
							<a
								href="https://securehoot.com"
								className="ml-1 text-white hover:text-gray-200 transition-colors duration-200"
							>
								SecureHoot
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
